<template>
  <div class="page mt-0">
    <h3 v-if="!error" class="success">Вы успешно отписаны от рассылки</h3>
    <h2 v-else class="error--text">{{ error }}</h2>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PollsEmails',
  data() {
    return {
      hash: this.$route.query['poll'] || null,
      error: false
    }
  },
  mounted() {
    this.GET_CONSULTANT_BY_HASH_AND_NOT_MAILING(this.hash).then(
      (response) => this.error = false
    ).catch(
      (error) => this.error = error.response.data.message
    )
  },
  methods: {
    ...mapActions('app', ['GET_CONSULTANT_BY_HASH_AND_NOT_MAILING'])
  }
}
</script>

<style scoped>

</style>
